<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br /><br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br />
        <br />
        <h3>TRANSVAGINAL (HISTERECTOMIA)</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>

      <!-- <p><h4> Relatório:</h4></p>

    <p> Ausência da imagem do útero.</p>

    <p>Ovário direito: {{laudo.ovarioDireito}} {{laudo.ovarioDireitoOutros}}
       , {{laudo.ovarioDireito2}} {{laudo.ovarioDireito2Outros}}</p>

    <p> {{laudo.imagemAnecoica}}
      <span v-if="laudo.imagemAnecoica === 'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' ">
        {{laudo.imagemAnecoicaCm}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica === 'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'">
        {{laudo.finoSeptoCm}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica === 'Apresenta imagem anecóica com debris, medindo'" >
        {{laudo.anecoicaDebrisCm}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica === 'Apresenta imagem anecóica multiseptada medindo'">
        {{laudo.anecoicaMultiseptadaCm}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica === 'Apresenta imagem heterogênea medindo'">
        {{laudo.anecoicaheterogenea1Cm}} x {{laudo.imagemHeterogeneaCm}} cm.
      </span>

      <span> {{laudo.imagemHeterogeneaOutro}}</span>
    </p>

    <p>Ovário esquerdo: {{laudo.ovarioEsquerdo1}}.</p>

    <p>Ovário esquerdo: {{laudo.ovarioEsquerdo2}} {{laudo.ovarioEsquerdo2Outro}} , {{laudo.ovarioEsquerdo3}} {{laudo.ovarioEsquerdo3Outro}}</p>

        <p> {{laudo.imagemAnecoica2}}
      <span v-if="laudo.imagemAnecoica2 === 'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' ">
        {{laudo.imagemAnecoicaCm2}} cm no seu maior diâmetro.
      </span>


      <span v-if="laudo.imagemAnecoica2 === 'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'">
        {{laudo.finoSeptoCm2}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica2 === 'Apresenta imagem anecóica com debris, medindo'" >
        {{laudo.anecoicaDebrisCm2}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica2 === 'Apresenta imagem anecóica multiseptada medindo'">
        {{laudo.anecoicaMultiseptadaCm2}} cm no seu maior diâmetro.
      </span>

      <span v-if="laudo.imagemAnecoica2 === 'Apresenta imagem heterogênea medindo'">
        {{laudo.anecoicaMultiseptadaCm3}} x {{laudo.imagemHeterogeneaCm2}} cm.
      </span>

      <span> {{laudo.imagemHeterogeneaOutro2}}</span>
    </p>


    <p>{{laudo.localizacao}} {{laudo.localizacaoOutro}}</p>

    <p>{{laudo.sacoDouglas}}</p>

    <p><h4>Impressão:</h4></p>

    <p>Histerectomia total prévia.</p>

    <p>{{laudo.histerectomiaTotalPrevia}} {{laudo.histerectomiaTotalPreviaOutro}}</p>





    <p><h4>Conclusão</h4></p>

    <p>Medidas do ovário direito: {{laudo.medidasOvarioDireito1}} x {{laudo.medidasOvarioDireito2}} x {{laudo.medidasOvarioDireito3}} cm e volume de  cm³.</p>

    <p>Medidas do ovário esquerdo: {{laudo.medidasOvarioEsquerdo1}} x {{laudo.medidasOvarioEsquerdo2}} x {{laudo.medidasOvarioEsquerdo3}} cm e volume de  cm³.</p>

    <p>Comentários Adcionais: {{laudo.comentariosAdcionais}}</p> -->
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    let folha = [];

    folha.push('\n');

    folha.push(`Ausência da imagem do útero.`);

    if (this.laudo.ovarioDireito) folha.push('Ovários');

    if (this.laudo.ovarioDireito)
      folha.push(
        `Ovário direito: ${this.laudo.ovarioDireito}${
          this.laudo.ovarioDireito == 'outro'
            ? this.laudo.ovarioDireitoOutros
            : ''
        }, ${this.laudo.ovarioDireito2}${
          this.laudo.ovarioDireito2 == 'outro'
            ? this.laudo.ovarioDireito2Outros
            : ''
        }.`
      );

    if (
      this.laudo.imagemAnecoica ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(`${this.laudo.imagemAnecoicaCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(`${this.laudo.finoSeptoCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(`${this.laudo.anecoicaDebrisCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(
        `${this.laudo.anecoicaMultiseptadaCm} cm no seu maior diâmetro.`
      );

    if (this.laudo.imagemAnecoica === 'Apresenta imagem heterogênea medindo')
      folha.push(
        `${this.laudo.anecoicaheterogenea1Cm} x ${this.laudo.imagemHeterogeneaCm} cm.`
      );

    // if (this.laudo.ovarioEsquerdo1)
    //   folha.push(`Ovário esquerdo: ${this.laudo.ovarioEsquerdo1}.`);

    if (this.laudo.ovarioEsquerdo2)
      folha.push(
        `Ovário esquerdo: ${this.laudo.ovarioEsquerdo2}${
          this.laudo.ovarioEsquerdo2 == 'outro'
            ? this.laudo.ovarioEsquerdo2Outro
            : ''
        }${
          this.laudo.ovarioEsquerdo3 == 'outro'
            ? this.laudo.ovarioEsquerdo3Outro
            : ''
        }.`
      );

    if (
      this.laudo.imagemAnecoica2 ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(
        `${this.laudo.imagemAnecoica2} ${this.laudo.imagemAnecoicaCm2} cm no seu maior diâmetro.`
      );
    else if (
      this.laudo.imagemAnecoica2 ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(
        `${this.laudo.imagemAnecoica2} ${this.laudo.finoSeptoCm2} cm no seu maior diâmetro.`
      );
    else if (
      this.laudo.imagemAnecoica2 ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(
        `${this.laudo.imagemAnecoica2} ${this.laudo.anecoicaDebrisCm2} cm no seu maior diâmetro.`
      );
    else if (
      this.laudo.imagemAnecoica2 ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(
        `${this.laudo.imagemAnecoica2} ${this.laudo.anecoicaMultiseptadaCm2} cm no seu maior diâmetro.`
      );
    else if (
      this.laudo.imagemAnecoica2 === 'Apresenta imagem heterogênea medindo'
    )
      folha.push(
        `${this.laudo.imagemAnecoica2} ${this.laudo.anecoicaMultiseptadaCm3} x ${this.laudo.imagemHeterogeneaCm2} cm.`
      );
    else if (this.laudo.imagemAnecoica2)
      folha.push(
        `${
          this.laudo.imagemAnecoica2 != 'outro'
            ? this.laudo.imagemAnecoica2
            : this.laudo.imagemHeterogeneaOutro2
        }`
      );

    if (this.laudo.localizacao)
      folha.push(
        `${this.laudo.localizacao != 'Outro' ? this.laudo.localizacao : ''} ${
          this.laudo.localizacaoOutro ? this.laudo.localizacaoOutro : ''
        }`
      );

    if (this.laudo.sacoDouglas) folha.push(`${this.laudo.sacoDouglas}.`);
    if (folha.length > 0) folha.splice(0, 0, 'Relatório');
    this.relatorio = [...folha];
    folha = [];

    folha.push(`Histerectomia total prévia.`);
    if (this.laudo.histerectomiaTotalPrevia)
      folha.push(`${this.laudo.histerectomiaTotalPrevia}.`);
    if (this.laudo.histerectomiaTotalPreviaOutro)
      folha.push(`${this.laudo.histerectomiaTotalPreviaOutro}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Impressão');
    this.impressao = [...folha];
    folha = [];

    if (this.laudo.histerectomiaTotalPrevia)
      folha.push(`Medidas do ovário direito: ${this.laudo.medidasOvarioDireito1}
     x ${this.laudo.medidasOvarioDireito2} x ${this.laudo.medidasOvarioDireito3} cm e volume de  cm³.`);
    if (this.laudo.histerectomiaTotalPrevia)
      folha.push(`Medidas do ovário esquerdo:  ${this.laudo.medidasOvarioEsquerdo1}
     x ${this.laudo.medidasOvarioEsquerdo2} x ${this.laudo.medidasOvarioEsquerdo3} cm e volume de  cm³.`);
    if (this.laudo.histerectomiaTotalPreviaOutro)
      folha.push(`${this.laudo.histerectomiaTotalPreviaOutro}.`);
    if (this.laudo.comentariosAdcionais)
      folha.push(`${this.laudo.comentariosAdcionais}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [...this.relatorio, ...this.impressao, ...this.conclusao];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 30));
    }
  },
  data() {
    return {
      relatorio: [],
      conclusao: [],
      impressao: [],
      tamanhoElemento: [],
      titulos: ['Relatório', 'Impressão', 'Conclusão', 'Ovários'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 3px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
